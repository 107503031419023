<template>
  <CinemaHead v-if="!pending && cinema && !isWidgetMode" :cinema />

  <PageContainer v-if="!pending && cinema" class="!pt-0">
    <PageGrid>
      <PageGridColumn v-if="!isWidgetMode">
        <CinemaNavigation :cinema />
      </PageGridColumn>

      <PageGridColumn class="py-8">
        <NuxtPage :cinema />
      </PageGridColumn>
    </PageGrid>
  </PageContainer>
</template>

<script setup lang="ts">
import type { City } from '#gql/default'

interface Props {
  city: City
}

const props = defineProps<Props>()
const route = useRoute()
const { isWidgetMode } = useWidgetMode()

const { cinema, pending } = await useCinema({
  variables: {
    urlSlug: route.params.cinemaSlug as string,
    cityUrlSlug: props.city.urlSlug as string,
    design: route.query.design as string | undefined,
  },
  throwError: true,
})

defineI18nRoute({
  paths: {
    en: '/cinema/[citySlug]/[cinemaSlug]',
    de: '/kino/[citySlug]/[cinemaSlug]',
    es: '/cine/[citySlug]/[cinemaSlug]',
  },
})

defineOptions({
  name: 'PageCinemaCitySlugCinemaSlug',
})
</script>
